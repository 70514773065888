<script lang="ts">
    import { onMount } from 'svelte';
    import type { User, Post, Data } from '../interfaces/types';
    import { postsStore, isLoading, currentPosts } from '@/stores/pagination';
    import { loadMorePosts } from '@/utils/posts-loader';
    import { infiniteScroll } from '@/actions/infinite-scroll';
    import {
        streamsStore,
        isStreamLoading as isStreamLoadingStore,
        currentStreams as currentStreamStore,
        initializeStreamsStore,
        resetStreamsStore,
    } from '@/stores/streams-pagination';
    import { loadMoreStreams } from '@/utils/streams-loader';

    interface CustomWindow extends Window {
        app: any;
    }

    const customWindow = window as unknown as CustomWindow;
    const app = customWindow.app;

    import toast from 'svelte-french-toast';
    import Widgets from '@/elements/profile/Widgets.svelte';
    import MessageAlert from '@/elements/MessageAlert.svelte';
    import PostsWrapper from '@/elements/Feed/PostsWrapper.svelte';
    import StreamsWrapper from '@/elements/Feed/StreamsWrapper.svelte';
    import OptionsModal from '@/elements/OptionsModal.svelte';
    import MessageDialog from '@/elements/MessageDialog.svelte';
    import ListAddDialog from '@/elements/ListAddDialog.svelte';
    import QRCodeDialog from '@/elements/QRCodeDialog.svelte';
    import LoginDialog from '@/elements/LoginDialog.svelte';
    import CheckoutDialog from '@/elements/checkout/CheckoutDialog.svelte';
    import ListFollowerDialog from '@/elements/ListFollowerDialog.svelte';
    import PointsToggle from '@/elements/profile/PointsToggle.svelte';

    import SubscribeButton from '@/elements/checkout/SubscribeButton.svelte';

    import EllipsisHorizontalIcon from '@/Icons/EllipsisHorizontalIcon.svelte';
    import ChatBubbleOvalIcon from '@/Icons/ChatBubbleOvalIcon.svelte';
    import ListBulletIcon from '@/Icons/ListBulletIcon.svelte';
    import QrCodeIcon from '@/Icons/QRCodeIcon.svelte';
    import ShareIcon from '@/Icons/ShareIcon.svelte';
    import GlobeIcon from '@/Icons/GlobeIcon.svelte';
    import CogToothIcon from '@/Icons/CogToothIcon.svelte';
    import CheckBadgeIcon from '@/Icons/CheckBadgeIcon.svelte';
    import CalendarIcon from '@/Icons/CalendarIcon.svelte';
    import LocationIcon from '@/Icons/LocationIcon.svelte';
    import LinkIcon from '@/Icons/LinkIcon.svelte';
    import ChevronDownIcon from '@/Icons/ChevronDownIcon.svelte';
    import ChevronUpIcon from '@/Icons/ChevronUpIcon.svelte';
    import CashOutline from '@/Icons/CashOutline.svelte';
    import PointLight from '@/Icons/PointLight.svelte';

    import Button from '$lib/components/ui/button/button.svelte';
    import { inertia } from '@inertiajs/svelte';

    export let data: Data;
    console.log('Profile data:', data);
    let {
        posts,
        user,
        hasSub,
        recentMedia: streams,
        activeFilter,
        filterTypeCounts,
        viewerHasChatAccess,
        lists,
        offer,
        isUserBlocked,
        isFollowing,
        showLoginDialog,
        reportTypes,
    } = data;

    console.log('Posts data:', posts.data);

    export let paginatorConfig: { next_page_url: string | null; hasMore: boolean };
    console.log('Paginator config:', paginatorConfig);

    export let paymentSettings = {
        stripeSecretKey: data.stripeSecretKey,
        stripePublicKey: data.stripePublicKey,
        isStripeCheckoutDisabled: data.isStripeCheckoutDisabled,
        paypalClientId: data.paypalClientId,
        paypalSecret: data.paypalSecret,
        isPaypalCheckoutDisabled: data.isPaypalCheckoutDisabled,
        coinbaseApiKey: data.coinbaseApiKey,
        isCoinbaseCheckoutDisabled: data.isCoinbaseCheckoutDisabled,
        nowPaymentApiKey: data.nowPaymentApiKey,
        isNowPaymentsCheckoutDisabled: data.isNowPaymentsCheckoutDisabled,
        isCCbillCredentialsProvided: data.isCCbillCredentialsProvided,
        paystackSecretKey: data.paystackSecretKey,
        isPaystackCheckoutDisabled: data.isPaystackCheckoutDisabled,
        isStripeOXXOProviderEnabled: data.isStripeOXXOProviderEnabled,
    };
    let loggedUserAvailableAmount = data.loggedUserAvailableAmount;
    let minPostDeletionLimit = data.minPostDeletionLimit;
    let postsData = posts.data;
    let loading = false;

    function handlePostAction(event: CustomEvent<{ action: string; postId: number }>) {
        const { action, postId } = event.detail;
    }

    function handleLoadingState(event: CustomEvent<{ loading: boolean }>) {
        loading = event.detail.loading;
    }

    export let userAvailableCredit: number = data.loggedUserAvailableAmount;
    export let creatorCanEarnMoney: boolean = data.creatorCanEarnMoney;
    export let getUserFollowingType = (userId: number, flag: boolean): string => data.userFollowingType;

    export let enforceEmailValidation: boolean = data.enforceEmailValidation;
    export let allowProfileQRCode: boolean = data.allowProfileQRCode;
    export let allowStreams: boolean = data.allowStreams;
    export let disableProfileBioExcerpt: boolean = data.disableProfileBioExcerpt;
    export let disableWebsiteLinkOnProfile: boolean = data.disableWebsiteLinkOnProfile;
    export let allowGenderPronouns: boolean = data.allowGenderPronouns;
    export let allowUsersEnablingOpenProfiles: boolean = data.allowUsersEnablingOpenProfiles;

    export let authenticatedUser: User;
    export let userLists: any[] = data.userLists;
    export let userFollowers: any[] = data.userFollowers;
    export let hasActiveStream: boolean = data.hasActiveStream;
    export let isEmailValidated: boolean = data.isEmailEnforcedAndValidated;

    export let prevPageCookie: boolean = false;
    export let isComingFromPostPage: boolean = data.isComingFromPostPage;
    export let currencyCode: string = data.currencyCode;
    export let pointsPriceDeposit: number = data.pointsPriceDeposit;

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    const stripTagsAndTrim = (str: string): string => str.replace(/<[^>]*>/g, '').trim();
    const formatWebsiteUrl = (url: string): string => url.replace(/^(https?:\/\/)?(www\.)?/, '');
    const formatDate = (date: string): string =>
        new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
    const transChoice = (key: string, count: number, replacements: Record<string, any>): string =>
        `${replacements.number} ${key}`;

    $: bioLength = user.bio?.length || 0;
    $: newlineCount = user.bio ? (user.bio.match(/\r\n/g) || []).length : 0;
    $: shouldTruncate = user.bio && (bioLength >= 85 || newlineCount > 1) && !disableProfileBioExcerpt;
    $: showSubscriptionSection =
        user.paid_profile &&
        (!allowUsersEnablingOpenProfiles || (allowUsersEnablingOpenProfiles && !user.open_profile));

    let showMessageDialog = false;

    function showNewMessageDialog() {
        showMessageDialog = true;
    }

    let showListAddModalState = false;

    function showListAddModal() {
        showListAddModalState = true;
    }

    let showQRCodeDialog = false;

    function getProfileQRCode() {
        showQRCodeDialog = true;
    }

    async function shareOrCopyLink() {
        const url = window.location.href;

        if (navigator.share) {
            try {
                await navigator.share({ title: document.title, url });
            } catch (error) {
                console.log('Error sharing:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(url);
                toast.success('Link copied to clipboard');
            } catch (error) {
                console.error('Failed to copy:', error);
            }
        }
    }

    function loginDialog() {
        showLoginDialog = true;
    }

    let showCheckoutDialog = false;

    function handleTipClick() {
        if (!authenticatedUser?.email_verified_at && enforceEmailValidation) {
            return;
        }
        if (!creatorCanEarnMoney) {
            return;
        }
        showCheckoutDialog = true;
    }

    let showOptionsModalState = false;

    function showOptionsModal() {
        showOptionsModalState = true;
    }

    async function manageFollowsAction(userId: number) {
        try {
            const type = isFollowing ? 'remove' : 'add';
            const data = new URLSearchParams();
            data.append('list_id', lists[0].id.toString());
            data.append('user_id', userId.toString());
            data.append('returnData', 'true');

            const requestMethod = type === 'add' ? 'POST' : 'DELETE';
            const requestUrl =
                type === 'add' ? `${app.baseUrl}/my/lists/members/save` : `${app.baseUrl}/my/lists/members/delete`;

            const response = await fetch(requestUrl, {
                method: requestMethod,
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
                credentials: 'same-origin',
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.message || 'Error updating follow status');
            }

            isFollowing = !isFollowing;
            user.follower_amount += isFollowing ? 1 : -1;
            await updateFollowerCount(userId);
        } catch (error) {
            toast.error('Failed to update follow status');
        }
    }

    async function updateFollowerCount(userId: number) {
        try {
            const response = await fetch(`${app.baseUrl}/followers/${userId}`);
            const result = await response.json();
            const followers = result.data?.length || 0;

            const followerElement = document.querySelector('.profile-follower-holder button');
            if (followerElement) {
                followerElement.innerHTML = `<span class='font-bold'>${followers}</span> Followers`;
            }
        } catch (error) {
            console.error('Error updating follower count:', error);
        }
    }

    function handleUpdateFollowerCount(event: CustomEvent<{ change: number }>) {
        user.follower_amount += event.detail.change;
    }

    let showFollowerDialog = false;

    function toggleFullDescription() {}

    let showBundles = false;

    function toggleBundles() {
        showBundles = !showBundles;
    }

    function setActiveFilter(filter: string | boolean) {
        activeFilter = filter;
    }

    function handleShowCheckout(event: CustomEvent) {
        const checkoutData = event.detail;
        showCheckoutDialog = true;
    }

    $: isStreamLoading = $isStreamLoadingStore;
    $: currentStreamData = $currentStreamStore;

    onMount(() => {
        postsStore.initialize(posts.data, paginatorConfig.next_page_url, paginatorConfig.hasMore);

        if (streams && streams.length > 0) {
            const streamsConfig = { next_page_url: streams.next_page_url || null, hasMore: !!streams.next_page_url };
            initializeStreamsStore(streams.data || streams, streamsConfig.next_page_url, streamsConfig.hasMore);
        }

        return () => {
            postsStore.reset();
            resetStreamsStore();
        };
    });

    function handleStreamsScroll() {
        if (activeFilter === 'streams') {
            loadMoreStreams();
        }
    }

    $: monthlyPrice = user?.subscription1Month?.price ? Number(user.subscription1Month.price) : 0;
    $: threeMonthPrice = user?.subscription3Months?.price ? Number(user.subscription3Months.price) : 0;
    $: sixMonthPrice = user?.subscription6Months?.price ? Number(user.subscription6Months.price) : 0;
    $: twelveMonthPrice = user?.subscription12Months?.price ? Number(user.subscription12Months.price) : 0;
</script>

<svelte:head>
    <title>{data.user.name}'s Profile</title>
</svelte:head>

<template>
    <div class="row">
        <div class="min-vh-100 col-12 col-md-8 border-right pr-md-0">
            <div class="profile-cover-bg">
                <img class="card-img-top centered-and-cropped" src="{user.cover}" alt="user-cover" />
            </div>
            <div class="d-flex justify-content-between align-items-center container">
                <div class="z-index-3 avatar-holder">
                    <img src="{user.avatar}" alt="{user.name}" class="rounded-circle" />
                </div>

                <div>
                    {#if !authenticatedUser || authenticatedUser.id !== user.id}
                        <div class="d-flex flex-row items-center">
                            {#if authenticatedUser}
                                <div>
                                    <span
                                        class="p-pill pointer-cursor to-tooltip ml-2"
                                        data-toggle="options"
                                        data-placement="top"
                                        title="Options"
                                        on:click="{showOptionsModal}"
                                    >
                                        <EllipsisHorizontalIcon />
                                    </span>
                                </div>

                                <div>
                                    <span
                                        class="p-pill pointer-cursor to-tooltip ml-2"
                                        data-placement="top"
                                        title="{!authenticatedUser.email_verified_at && enforceEmailValidation
                                            ? 'Please verify your account'
                                            : !creatorCanEarnMoney
                                              ? 'This creator cannot earn money yet'
                                              : 'Send a tip'}"
                                        on:click="{handleTipClick}"
                                    >
                                        <CashOutline />
                                    </span>
                                </div>

                                <div>
                                    {#if hasSub || viewerHasChatAccess}
                                        <span
                                            class="p-pill pointer-cursor ml-2"
                                            data-placement="top"
                                            title="Send a message"
                                            on:click="{showNewMessageDialog}"
                                        >
                                            <ChatBubbleOvalIcon />
                                        </span>
                                    {:else}
                                        <span
                                            class="p-pill pointer-cursor ml-2"
                                            data-placement="top"
                                            title="DMs unavailable without subscription"
                                        >
                                            <ChatBubbleOvalIcon />
                                        </span>
                                    {/if}
                                </div>

                                <span
                                    class="p-pill pointer-cursor ml-2"
                                    data-placement="top"
                                    title="Add to your lists"
                                    on:click="{showListAddModal}"
                                >
                                    <ListBulletIcon />
                                </span>

                                {#if allowProfileQRCode}
                                    <div>
                                        <span
                                            class="p-pill pointer-cursor ml-2"
                                            data-placement="top"
                                            title="Get profile QR code"
                                            on:click="{getProfileQRCode}"
                                        >
                                            <QrCodeIcon />
                                        </span>
                                    </div>
                                {/if}

                                <span
                                    class="p-pill pointer-cursor ml-2"
                                    data-placement="top"
                                    title="Copy profile link"
                                    on:click="{shareOrCopyLink}"
                                >
                                    <ShareIcon />
                                </span>

                                <div>
                                    <a href="/{user.username}/network">
                                        <span class="p-pill pointer-cursor ml-2" data-placement="top" title="Network">
                                            <GlobeIcon />
                                        </span>
                                    </a>
                                </div>

                                {#if authenticatedUser}
                                    <div
                                        id="followButton"
                                        class="item-center follow-btn flex h-full w-24 justify-center rounded-full !px-2 !py-2 {isFollowing
                                            ? 'followed'
                                            : ''} pointer-cursor ml-2 font-medium"
                                        data-state="{isFollowing ? 'following' : 'follow'}"
                                        on:click|preventDefault="{() => manageFollowsAction(user.id)}"
                                    >
                                        <span>
                                            {isFollowing ? 'Following' : 'Follow'}
                                        </span>
                                    </div>
                                {/if}
                            {/if}
                        </div>
                    {:else}
                        <div class="d-flex flex-row">
                            <div class="mr-2">
                                <a href="/my/settings" class="p-pill p-pill-text pointer-cursor ml-2">
                                    <CogToothIcon size="size-5" />
                                    <span class="d-none d-md-block ml-1">Edit profile</span>
                                    <span class="d-block d-md-none ml-1">Edit</span>
                                </a>
                            </div>

                            {#if allowProfileQRCode}
                                <div>
                                    <span
                                        class="p-pill pointer-cursor ml-2"
                                        data-placement="top"
                                        title="Get profile QR code"
                                        on:click="{getProfileQRCode}"
                                    >
                                        <QrCodeIcon />
                                    </span>
                                </div>
                            {/if}

                            <div>
                                <a href="/{user.username}/network">
                                    <span class="p-pill pointer-cursor ml-2" data-placement="top" title="My Network">
                                        <GlobeIcon />
                                    </span>
                                </a>
                            </div>

                            <div>
                                <span
                                    class="p-pill pointer-cursor ml-2"
                                    data-placement="top"
                                    title="Copy profile link"
                                    on:click="{shareOrCopyLink}"
                                >
                                    <ShareIcon />
                                </span>
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
            {#if isUserBlocked}
                <div class="alert alert-danger" role="alert">
                    This user is blocked. This means they can't see your posts or send you messages.
                </div>
            {/if}
            <div class="container pl-0 pr-0 pt-2">
                <div class="d-flex justify-between pr-4">
                    <div class="pl-4 pr-4 pt-2">
                        <h5 class="d-flex align-items-center mb-1 font-bold text-black dark:!text-white">
                            <span class="mr-1">{user.name}</span>
                            {#if user.email_verified_at && user.birthdate && user.verification?.status === 'verified'}
                                <span data-toggle="tooltip" data-placement="top" title="Verified user">
                                    <CheckBadgeIcon size="size-5" />
                                </span>
                            {/if}
                            {#if hasActiveStream}
                                <span data-toggle="tooltip" data-placement="right" title="Live streaming">
                                    <div class="blob red ml-3"></div>
                                </span>
                            {/if}
                        </h5>
                        <h6 class="text-neutral-800 dark:text-white">
                            <span><span>@</span>{user.username}</span>
                        </h6>
                    </div>
                    <div class="profile-follower-holder">
                        <button
                            type="button"
                            class="mb-2 rounded-md border-0 bg-transparent text-black transition duration-300 hover:text-neutral-600 dark:!text-white hover:dark:text-gray-300"
                            on:click="{() => (showFollowerDialog = true)}"
                        >
                            <span class="font-bold">{user.follower_amount}</span> Followers
                        </button>
                    </div>
                </div>

                <div class="profile-description-holder pb-2 pl-4 pr-4 pt-2">
                    <div class="description-content {shouldTruncate ? 'line-clamp-1' : ''}">
                        {#if user.bio}
                            {user.bio}
                        {:else}
                            No description available.
                        {/if}
                    </div>

                    {#if shouldTruncate}
                        <span class="pointer-cursor text-primary" on:click="{toggleFullDescription}">
                            <span class="label-more">More info</span>
                            <span class="label-less d-none">Show less</span>
                        </span>
                    {/if}
                </div>

                <div class="d-flex flex-column flex-md-row justify-content-md-between mb-3 mt-1 pb-2 pl-4 pr-4">
                    <div class="d-flex align-items-center text-truncate mb-md-0 mb-0 mr-2">
                        <div class="mr-1">
                            <CalendarIcon />
                        </div>
                        <div class="text-truncate ml-1">
                            {formatDate(user.created_at)}
                        </div>
                    </div>

                    {#if user.location}
                        <div class="d-flex align-items-center text-truncate mb-md-0 mb-0 mr-2">
                            <div class="mr-1">
                                <LocationIcon />
                            </div>
                            <div class="text-truncate ml-1">
                                {user.location}
                            </div>
                        </div>
                    {/if}

                    {#if user.website}
                        <div class="d-flex align-items-center text-truncate mb-md-0 mb-0 mr-2">
                            <div class="mr-1">
                                <LinkIcon />
                            </div>
                            <div class="text-truncate ml-1">
                                <a href="{user.website}" target="_blank" rel="nofollow">
                                    {formatWebsiteUrl(user.website)}
                                </a>
                            </div>
                        </div>
                    {/if}

                    {#if allowGenderPronouns && user.gender_pronoun}
                        <div class="d-flex align-items-center text-truncate mb-md-0 mb-0 mr-2">
                            <div class="text-truncate ml-1">
                                {user.gender_pronoun}
                            </div>
                        </div>
                    {/if}
                </div>

                <div class="bg-separator border-top border-bottom"></div>

                <MessageAlert classes="px-2 pt-4" />

                {#if showSubscriptionSection}
                    {#if (!authenticatedUser || authenticatedUser.id !== user.id) && !hasSub}
                        <div class="subscription-holder p-4">
                            <h6 class="font-weight-bold text-uppercase mb-3">Subscription</h6>

                            {#if offer.length}
                                <h5 class="text-bold m-0">
                                    Limited time offer: {offer.discountAmount}% off - {offer.daysRemaining} days remaining
                                </h5>
                                <small
                                    >Offer ends {new Date(offer.expiresAt).toLocaleDateString('en-US', {
                                        day: 'numeric',
                                        month: 'short',
                                    })}</small
                                >
                            {/if}

                            {#if hasSub}
                                <button class="btn btn-round btn-lg btn-primary btn-block mb-2 mt-3 text-center">
                                    <span>Subscribed</span>
                                </button>
                            {:else}
                                {#if authenticatedUser && !isEmailValidated}
                                    <i>
                                        Your email address is not verified.
                                        <a href="/verify-email">Click here</a>
                                        to re-send the confirmation email.
                                    </i>
                                {/if}

                                <SubscribeButton
                                    duration="30"
                                    {user}
                                    {authenticatedUser}
                                    {enforceEmailValidation}
                                    {creatorCanEarnMoney}
                                    {isEmailValidated}
                                    {userAvailableCredit}
                                    currencySymbol="{currencyCode}"
                                    bind:showLoginDialog
                                    on:showCheckout="{handleShowCheckout}"
                                />
                                <div class="data-profile-month" data-profile-month="{user.profile_access_price}"></div>

                                <PointsToggle
                                    {pointsPriceDeposit}
                                    {monthlyPrice}
                                    threeMonthPrice="{threeMonthPrice * 3}"
                                    sixMonthPrice="{sixMonthPrice * 6}"
                                    twelveMonthPrice="{twelveMonthPrice * 12}"
                                />

                                <div class="d-flex justify-content-between mt-1">
                                    {#if user.profile_access_price_6_months || user.profile_access_price_12_months}
                                        <small>
                                            <div
                                                class="pointer-cursor d-flex align-items-center"
                                                on:click="{toggleBundles}"
                                            >
                                                <div class="label-icon ml-1 flex items-center !text-xl !text-gray-300">
                                                    {#if showBundles}
                                                        <ChevronUpIcon />
                                                    {:else}
                                                        <ChevronDownIcon />
                                                    {/if}
                                                </div>
                                                &nbsp;
                                                <div class="font-semibold text-gray-300" class:hidden="{showBundles}">
                                                    Subscriptions bundles
                                                </div>
                                                <div class="font-semibold text-gray-300" class:hidden="{!showBundles}">
                                                    Hide cheaper billing timeframes
                                                </div>
                                            </div>
                                        </small>
                                    {/if}

                                    {#if offer.length}
                                        <small>
                                            Regular price: {currencyCode ?? 'USD'}
                                            {offer.old_profile_access_price}
                                        </small>
                                    {/if}
                                </div>

                                <div id="pointdeposit" data-points-deposit="{pointsPriceDeposit}"></div>

                                {#if user.profile_access_price_6_months || user.profile_access_price_12_months || user.profile_access_price_3_months}
                                    <div class="subscription-bundles mt-4" class:hidden="{!showBundles}">
                                        {#if user.profile_access_price_3_months}
                                            <div
                                                class="data-profile-3months"
                                                data-profile-3months="{user.profile_access_price_3_months * 3}"
                                            ></div>
                                            <SubscribeButton
                                                duration="90"
                                                {user}
                                                {authenticatedUser}
                                                {enforceEmailValidation}
                                                {creatorCanEarnMoney}
                                                {isEmailValidated}
                                                {userAvailableCredit}
                                                currencySymbol="{currencyCode}"
                                                bind:showLoginDialog
                                                on:showCheckout="{handleShowCheckout}"
                                            />
                                        {/if}

                                        {#if user.profile_access_price_6_months}
                                            <div
                                                class="data-profile-6months"
                                                data-profile-6months="{user.profile_access_price_6_months * 6}"
                                            ></div>
                                            <SubscribeButton
                                                duration="182"
                                                {user}
                                                {authenticatedUser}
                                                {enforceEmailValidation}
                                                {creatorCanEarnMoney}
                                                {isEmailValidated}
                                                {userAvailableCredit}
                                                currencySymbol="{currencyCode}"
                                                bind:showLoginDialog
                                                on:showCheckout="{handleShowCheckout}"
                                            />
                                        {/if}

                                        {#if user.profile_access_price_12_months}
                                            <div
                                                class="data-profile-12months"
                                                data-profile-12months="{user.profile_access_price_12_months * 12}"
                                            ></div>
                                            <SubscribeButton
                                                duration="365"
                                                {user}
                                                {authenticatedUser}
                                                {enforceEmailValidation}
                                                {creatorCanEarnMoney}
                                                {isEmailValidated}
                                                {userAvailableCredit}
                                                currencySymbol="{currencyCode}"
                                                bind:showLoginDialog
                                                on:showCheckout="{handleShowCheckout}"
                                            />
                                        {/if}
                                    </div>
                                {/if}
                            {/if}
                        </div>
                        <div class="bg-separator border-top border-bottom"></div>
                    {/if}
                {:else if !authenticatedUser || (authenticatedUser && authenticatedUser.id !== user.id)}
                    <div class="subscription-holder p-4">
                        <h6 class="font-weight-bold text-uppercase mb-3">Follow this creator</h6>
                        {#if authenticatedUser}
                            <Button
                                variant="default"
                                size="lg"
                                class="w-full"
                                on:click="{() => manageFollowsAction(user.id)}"
                            >
                                <span class="manage-follows-text">{isFollowing ? 'Following' : 'Follow'}</span>
                            </Button>
                        {:else}
                            <Button variant="default" size="lg" class="w-full" on:click="{loginDialog}">
                                <span>Follow</span>
                            </Button>
                        {/if}
                    </div>
                    <div class="bg-separator border-top border-bottom"></div>
                {/if}

                <div class="inline-border-tabs mt-3">
                    <nav class="nav nav-pills nav-justified text-bold">
                        <a
                            class="nav-item nav-link {activeFilter === false ? 'active' : ''}"
                            href="/{user.username}"
                            use:inertia
                            on:click|preventDefault="{() => setActiveFilter(false)}"
                        >
                            {transChoice('posts', posts.total, { number: posts.total })}
                        </a>

                        {#if filterTypeCounts.image > 0}
                            <a
                                class="nav-item nav-link {activeFilter === 'image' ? 'active' : ''}"
                                href="/{user.username}?filter=image"
                                use:inertia
                                on:click|preventDefault="{() => setActiveFilter('image')}"
                            >
                                {transChoice('images', filterTypeCounts.image, { number: filterTypeCounts.image })}
                            </a>
                        {/if}

                        {#if filterTypeCounts.video > 0}
                            <a
                                class="nav-item nav-link {activeFilter === 'video' ? 'active' : ''}"
                                href="/{user.username}?filter=video"
                                use:inertia
                                on:click|preventDefault="{() => setActiveFilter('video')}"
                            >
                                {transChoice('videos', filterTypeCounts.video, { number: filterTypeCounts.video })}
                            </a>
                        {/if}

                        {#if filterTypeCounts.audio > 0}
                            <a
                                class="nav-item nav-link {activeFilter === 'audio' ? 'active' : ''}"
                                href="/{user.username}?filter=audio"
                                use:inertia
                                on:click|preventDefault="{() => setActiveFilter('audio')}"
                            >
                                {transChoice('audio', filterTypeCounts.audio, { number: filterTypeCounts.audio })}
                            </a>
                        {/if}

                        <!-- {#if allowStreams && filterTypeCounts.streams > 0} -->
                        <a
                            class="nav-item nav-link {activeFilter === 'streams' ? 'active' : ''}"
                            href="/{user.username}?filter=streams"
                            use:inertia
                            on:click|preventDefault="{() => setActiveFilter('streams')}"
                        >
                            {transChoice('streams', filterTypeCounts.streams, { number: filterTypeCounts.streams })}
                        </a>
                        <!-- {/if} -->
                    </nav>
                </div>
                <div
                    class="justify-content-center align-items-center {prevPageCookie && isComingFromPostPage
                        ? 'mt-3'
                        : 'mt-4'}"
                    use:infiniteScroll="{{ callback: activeFilter === 'streams' ? loadMoreStreams : loadMorePosts }}"
                >
                    {#if activeFilter === 'streams'}
                        <div class="streams-box streams-wrapper mb-4 mt-4">
                            <StreamsWrapper
                                streams="{$currentStreamStore}"
                                showLiveIndicators="{true}"
                                showUsername="{false}"
                            />
                        </div>
                        {#if $isStreamLoadingStore}
                            <div class="loading-spinner">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        {/if}
                    {:else}
                        <div class="feed-box posts-wrapper mt-0">
                            <PostsWrapper
                                data="{$currentPosts}"
                                authUser="{authenticatedUser}"
                                reportStatuses="{reportTypes}"
                                {paymentSettings}
                                {loggedUserAvailableAmount}
                                {minPostDeletionLimit}
                                {creatorCanEarnMoney}
                                on:loadingState="{handleLoadingState}"
                                on:postAction="{handlePostAction}"
                            />
                        </div>
                        {#if $isLoading}
                            <div class="loading-spinner">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        {/if}
                    {/if}
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 d-none d-md-block pt-3">
            <Widgets {data} />
        </div>
    </div>
</template>

{#if authenticatedUser}
    <OptionsModal
        bind:show="{showOptionsModalState}"
        bind:isUserBlocked
        {user}
        {lists}
        {reportTypes}
        {app}
        on:blockStateChanged="{({ detail }) => (isUserBlocked = detail.isUserBlocked)}"
    />
    <CheckoutDialog
        bind:show="{showCheckoutDialog}"
        {user}
        data="{postsData}"
        {authenticatedUser}
        {userAvailableCredit}
        transactionType="tip"
    />
    <MessageDialog bind:show="{showMessageDialog}" {user} hasContacts="{hasSub || viewerHasChatAccess}" {app} />
    <ListAddDialog
        bind:show="{showListAddModalState}"
        {lists}
        userId="{user.id}"
        bind:isFollowing
        bind:isUserBlocked
        {app}
        on:followingChange="{({ detail }) => (isFollowing = detail.isFollowing)}"
        on:blockStateChanged="{({ detail }) => (isUserBlocked = detail.isUserBlocked)}"
        on:updateFollowerCount="{handleUpdateFollowerCount}"
    />
    <QRCodeDialog bind:show="{showQRCodeDialog}" username="{user.username}" />
    <ListFollowerDialog bind:show="{showFollowerDialog}" userId="{user.id}" {app} />
{:else}
    <LoginDialog bind:show="{showLoginDialog}" {user} />
{/if}

<style>
    .loading-spinner {
        display: flex;
        justify-content: center;
        padding: 2rem;
    }
</style>
